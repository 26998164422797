import React from "react"
import Icon from "../icon/icon"
import styles from "./table.module.scss"

const Table = ({data}) => (
  <div className={styles["table"]}>
    <div className={styles["table-columns"]}>
      {data.columns.map((item, index) =>
        <div key={index} className={styles["table-columns_column"]}><h2>{item}</h2></div>
      )}
    </div>
      {data.rows.map((row, index) => (
        <div key={index} className={[styles["table-rows"], index % 2 !== 0 ? styles["table-rows_bg"] : ""].join(' ')}>
          {Object.keys(row).map((item, index) =>
          <div key={index} className={styles["table-rows_row"]}>
            {typeof row[item] === "boolean" &&
            <span className={styles["table-rows_row_icon"]}>
              <Icon icon={`${row[item] ? "tick" : "cross"}-icon.svg`} size="30px"/>
            </span>
            }
            <span className={styles[`table-rows_row${item === "desc"? "_desc" : "_text"}`]}><p>{row[item]}</p></span>
          </div>
          )}
        </div>
      ))}
  </div>
);

export default Table