import React from "react"
import { navigate } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import Table from "../components/table/table"
import ColumnContainer from "../components/columnContainer/columnContainer"
import ImageBg from "../components/imageBg/imageBg"
import Card from "../components/card/card"
import LaunchForm from "../components/launchForm/launchForm"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"

const tcPdf = <IFrame file="../TC.pdf" title="Términos de uso"/>;
const pcPdf = <IFrame file="../PC.pdf" title="Política de privacidad"/>;
// const footerDesc = "";
const contentMaxWidth = "980px";

const dataTable = {
  columns: ['','EVVA', "Bancos"],
  rows: [
    {
      desc: "Cuenta empresarial línea",
      evva: true,
      banks: false
    },
    {
      desc: "Tarjeta de débito empresarial",
      evva: true,
      banks: true
    },
    {
      desc: "Tiempo de apertura",
      evva: "En minutos",
      banks: "2-4 semanas"
    },
    {
      desc: "Tipo de experiencia",
      evva: "100% digital",
      banks: "Larga espera en sucursal"
    },
    {
      desc: "Productos financieros personalizados",
      evva: true,
      banks: false
    },
    {
      desc: "Controles de seguridad desde tu celular",
      evva: true,
      banks: false
    },
    {
      desc: "Servicio de atención",
      evva: "Personalizado",
      banks: "Deficiente"
    }
  ]
};

const IndexAlt = () => {
  const appState = useSelector(state => state.appReducer);
  const dispatch = useDispatch();

  const [cookies, setCookie] = useCookies(['consent']);

  const launchFormHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    const modal = {
      display: true,
      content: <LaunchForm lang={appState.lang} title="¡Gracias por tu interés!" subtitle="Por ahora el acceso a la plataforma es sólo por invitación. Regístrate y solicita tu acceso cuanto antes." amplitude={appState.amplitude} amplitudeClickEvent="PreSignUpCreated"/>
    };

    dispatch(ACTIONS.showModal(modal))
  };

  const termsUseHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
  };

  const privacyPolicyHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
  };

  const cookieHandler = () => {
    setCookie("consent", "dismiss", { path: '/' });
  };

  const goFinancingHandler = () => {
    navigate("/financiamiento/")
  };

  const closeModal = () => dispatch(ACTIONS.hideModal());

  return (
    <Layout lang={appState.lang} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
      <SEO lang={appState.lang}/>
      {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/> }
      {!cookies.consent &&
      <OptOut text="Utilizamos cookies para garantizar que tengas la mejor experiencia en nuestro sitio web." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="Ver Política de privacidad" cookieAction={cookieHandler}/>
      }
      <MainSection title="EVVA la cuenta bancaria digital para PyMEs y Startups." description="<h3>Para Sociedades SAS, SAPI, SA de CV o SC. Adiós largas filas en el banco y el papeleo. con EVVA solicita tu cuenta empresarial en tan sólo 10 minutos.</h3>" position="left" image="hero-home.png" actionLabel="Solicita tu invitación" action={launchFormHandler} amplitude={appState.amplitude} amplitudeClickEvent="ClickPreSignUp">
        <AppBar pathname={appState.path}/>
      </MainSection>
      <PageSection>
        <div style={{marginTop: "-120px", zIndex: "1"}}>
          <Card
              subtitle="Obtén financiamiento para tu negocio"
              description="<p>Hemos unido fuerzas con Uniclick by Unifin, líder en el mercado de financiamiento, que ha impulsado a miles de PyMEs en México.</p>"
              descriptionColor="#363636"
              image="search-icon.svg"
              imageWidth="240px"
              imageHeight="160px"
              actionLabel="Saber mas"
              action={goFinancingHandler}
              actionVariant="outlined"
              bgColor="#fff"
              horizontal
              padding
              shadow
          />
        </div>
      </PageSection>
      <PageSection maxColumns="2">
        <div style={{height: "510px"}}>
          <ImageBg width="80%" image="evva-features-phone.png" />
        </div>
        <ColumnContainer>
          <Card
              title="✔ Para empresas"
              description="<p>No importa si eres Startup o PyME, SAS, SAPI, SA de CV o SC. Abre tu cuenta empresarial en minutos.</p>"
              padding
              shadow
          />
          <Card
              title="✔ 100% en línea"
              description="<p>Haz la apertura desde tu celular y realiza cualquier operación. Sin papeleo ni vueltas al banco.</p>"
              padding
              shadow
          />
          <Card
              title="✔ Segura"
              description="<p>Cada movimiento de tus colaboradores es verificado. Evita cualquier desvío de dinero.</p>"
              padding
              shadow
          />
        </ColumnContainer>
      </PageSection>
      <MainSection title="Separa tus gastos personales" description="<h3>Te daremos una tarjeta de débito exclusivamente para los gastos de tu negocio.</h3>" position="left" image="evva-debit-card.jpg">
        <div style={{height: "100px"}} />
      </MainSection>
      <PageSection>
        <div style={{marginTop: "-160px", zIndex: "1"}}>
          <Table data={dataTable}/>
        </div>
      </PageSection>
      <PageSection title="Con EVVA estás protegido" description="La cuenta bancaria es emitida por una entidad financiera totalmente regulada por CONDUSEF y CNBV. Tu cuenta está protegida por el Programa de Titulares Garantizados por el IPAB">
        <div style={{margin: "auto", maxWidth: contentMaxWidth}}>
          <img src="../logos-protected.png" width="100%" alt="Estás protegido por CNBV, Condusef e IPAB"/>
        </div>
      </PageSection>
      <SecondarySection
        title="Juntos crecemos"
        shortDescription="De emprendedores para emprendedores. Somos gente como tú que entiende tus necesidades. Nuestro objetivo final es que te enfoques en lo que realmente importa: tu empresa."
        position="center"
        image="evva-together.jpg"
        actionLabel="Solicita tu invitación"
        action={launchFormHandler}
        amplitude={appState.amplitude}
        amplitudeClickEvent="ClickPreSignUp"
      />
    </Layout>
  )
};

export default IndexAlt